'use strict'

module.exports = powerquery
powerquery.displayName = 'powerquery'
powerquery.aliases = []
function powerquery(Prism) {
  // https://docs.microsoft.com/en-us/powerquery-m/power-query-m-language-specification
  Prism.languages.powerquery = {
    comment: {
      pattern: /(^|[^\\])(?:\/\*[\s\S]*?\*\/|(?:\/\/).*)/,
      lookbehind: true
    },
    'quoted-identifier': {
      pattern: /#"(?:[^"\r\n]|"")*"(?!")/,
      greedy: true,
      alias: 'variable'
    },
    string: {
      pattern: /"(?:[^"\r\n]|"")*"(?!")/,
      greedy: true
    },
    constant: [
      /\bDay\.(?:Sunday|Monday|Tuesday|Wednesday|Thursday|Friday|Saturday)\b/,
      /\bTraceLevel\.(?:Critical|Error|Information|Verbose|Warning)\b/,
      /\bOccurrence\.(?:First|Last|All)\b/,
      /\bOrder\.(?:Ascending|Descending)\b/,
      /\bRoundingMode\.(?:AwayFromZero|Down|ToEven|TowardZero|Up)\b/,
      /\bMissingField\.(?:Error|Ignore|UseNull)\b/,
      /\bQuoteStyle\.(?:Csv|None)\b/,
      /\bJoinKind\.(?:Inner|LeftOuter|RightOuter|FullOuter|LeftAnti|RightAnti)\b/,
      /\bGroupKind\.(?:Global|Local)\b/,
      /\bExtraValues\.(?:List|Ignore|Error)\b/,
      /\bJoinAlgorithm\.(?:Dynamic|PairwiseHash|SortMerge|LeftHash|RightHash|LeftIndex|RightIndex)\b/,
      /\bJoinSide\.(?:Left|Right)\b/,
      /\bPrecision\.(?:Double|Decimal)\b/,
      /\bRelativePosition\.From(?:End|Start)\b/,
      /\bTextEncoding\.(?:Ascii|BigEndianUnicode|Unicode|Utf8|Utf16|Windows)\b/,
      /\b(?:Any|Binary|Date|DateTime|DateTimeZone|Duration|Int8|Int16|Int32|Int64|Function|List|Logical|None|Number|Record|Table|Text|Time)\.Type\b/,
      /\bnull\b/
    ],
    boolean: /\b(?:true|false)\b/,
    keyword:
      /\b(?:and|as|each|else|error|if|in|is|let|meta|not|nullable|optional|or|otherwise|section|shared|then|try|type)\b|#(?:binary|date|datetime|datetimezone|duration|infinity|nan|sections|shared|table|time)\b/,
    function: {
      pattern: /(^|[^#\w.])(?!\d)[\w.]+(?=\s*\()/,
      lookbehind: true
    },
    'data-type': {
      pattern:
        /\b(?:any|anynonnull|binary|date|datetime|datetimezone|duration|function|list|logical|none|number|record|table|text|time|type)\b/,
      alias: 'variable'
    },
    number: {
      pattern:
        /\b0x[\da-f]+\b|(?:[+-]?(?:\b\d+\.)?\b\d+|[+-]\.\d+|(^|[^.])\B\.\d+)(?:e[+-]?\d+)?\b/i,
      lookbehind: true
    },
    operator: /[-+*\/&?@^]|<(?:=>?|>)?|>=?|=>?|\.\.\.?/,
    punctuation: /[,;\[\](){}]/
  }
  Prism.languages.pq = Prism.languages['powerquery']
  Prism.languages.mscript = Prism.languages['powerquery']
}
