'use strict'

var refractor = require('./core.js')

module.exports = refractor

refractor.register(require('./lang/abap.js'))
refractor.register(require('./lang/abnf.js'))
refractor.register(require('./lang/actionscript.js'))
refractor.register(require('./lang/ada.js'))
refractor.register(require('./lang/agda.js'))
refractor.register(require('./lang/al.js'))
refractor.register(require('./lang/antlr4.js'))
refractor.register(require('./lang/apacheconf.js'))
refractor.register(require('./lang/apex.js'))
refractor.register(require('./lang/apl.js'))
refractor.register(require('./lang/applescript.js'))
refractor.register(require('./lang/aql.js'))
refractor.register(require('./lang/arduino.js'))
refractor.register(require('./lang/arff.js'))
refractor.register(require('./lang/asciidoc.js'))
refractor.register(require('./lang/asm6502.js'))
refractor.register(require('./lang/aspnet.js'))
refractor.register(require('./lang/autohotkey.js'))
refractor.register(require('./lang/autoit.js'))
refractor.register(require('./lang/avisynth.js'))
refractor.register(require('./lang/avro-idl.js'))
refractor.register(require('./lang/bash.js'))
refractor.register(require('./lang/basic.js'))
refractor.register(require('./lang/batch.js'))
refractor.register(require('./lang/bbcode.js'))
refractor.register(require('./lang/bicep.js'))
refractor.register(require('./lang/birb.js'))
refractor.register(require('./lang/bison.js'))
refractor.register(require('./lang/bnf.js'))
refractor.register(require('./lang/brainfuck.js'))
refractor.register(require('./lang/brightscript.js'))
refractor.register(require('./lang/bro.js'))
refractor.register(require('./lang/bsl.js'))
refractor.register(require('./lang/c.js'))
refractor.register(require('./lang/cfscript.js'))
refractor.register(require('./lang/chaiscript.js'))
refractor.register(require('./lang/cil.js'))
refractor.register(require('./lang/clojure.js'))
refractor.register(require('./lang/cmake.js'))
refractor.register(require('./lang/cobol.js'))
refractor.register(require('./lang/coffeescript.js'))
refractor.register(require('./lang/concurnas.js'))
refractor.register(require('./lang/coq.js'))
refractor.register(require('./lang/cpp.js'))
refractor.register(require('./lang/crystal.js'))
refractor.register(require('./lang/csharp.js'))
refractor.register(require('./lang/cshtml.js'))
refractor.register(require('./lang/csp.js'))
refractor.register(require('./lang/css-extras.js'))
refractor.register(require('./lang/csv.js'))
refractor.register(require('./lang/cypher.js'))
refractor.register(require('./lang/d.js'))
refractor.register(require('./lang/dart.js'))
refractor.register(require('./lang/dataweave.js'))
refractor.register(require('./lang/dax.js'))
refractor.register(require('./lang/dhall.js'))
refractor.register(require('./lang/diff.js'))
refractor.register(require('./lang/django.js'))
refractor.register(require('./lang/dns-zone-file.js'))
refractor.register(require('./lang/docker.js'))
refractor.register(require('./lang/dot.js'))
refractor.register(require('./lang/ebnf.js'))
refractor.register(require('./lang/editorconfig.js'))
refractor.register(require('./lang/eiffel.js'))
refractor.register(require('./lang/ejs.js'))
refractor.register(require('./lang/elixir.js'))
refractor.register(require('./lang/elm.js'))
refractor.register(require('./lang/erb.js'))
refractor.register(require('./lang/erlang.js'))
refractor.register(require('./lang/etlua.js'))
refractor.register(require('./lang/excel-formula.js'))
refractor.register(require('./lang/factor.js'))
refractor.register(require('./lang/false.js'))
refractor.register(require('./lang/firestore-security-rules.js'))
refractor.register(require('./lang/flow.js'))
refractor.register(require('./lang/fortran.js'))
refractor.register(require('./lang/fsharp.js'))
refractor.register(require('./lang/ftl.js'))
refractor.register(require('./lang/gap.js'))
refractor.register(require('./lang/gcode.js'))
refractor.register(require('./lang/gdscript.js'))
refractor.register(require('./lang/gedcom.js'))
refractor.register(require('./lang/gherkin.js'))
refractor.register(require('./lang/git.js'))
refractor.register(require('./lang/glsl.js'))
refractor.register(require('./lang/gml.js'))
refractor.register(require('./lang/gn.js'))
refractor.register(require('./lang/go.js'))
refractor.register(require('./lang/graphql.js'))
refractor.register(require('./lang/groovy.js'))
refractor.register(require('./lang/haml.js'))
refractor.register(require('./lang/handlebars.js'))
refractor.register(require('./lang/haskell.js'))
refractor.register(require('./lang/haxe.js'))
refractor.register(require('./lang/hcl.js'))
refractor.register(require('./lang/hlsl.js'))
refractor.register(require('./lang/hoon.js'))
refractor.register(require('./lang/hpkp.js'))
refractor.register(require('./lang/hsts.js'))
refractor.register(require('./lang/http.js'))
refractor.register(require('./lang/ichigojam.js'))
refractor.register(require('./lang/icon.js'))
refractor.register(require('./lang/icu-message-format.js'))
refractor.register(require('./lang/idris.js'))
refractor.register(require('./lang/iecst.js'))
refractor.register(require('./lang/ignore.js'))
refractor.register(require('./lang/inform7.js'))
refractor.register(require('./lang/ini.js'))
refractor.register(require('./lang/io.js'))
refractor.register(require('./lang/j.js'))
refractor.register(require('./lang/java.js'))
refractor.register(require('./lang/javadoc.js'))
refractor.register(require('./lang/javadoclike.js'))
refractor.register(require('./lang/javastacktrace.js'))
refractor.register(require('./lang/jexl.js'))
refractor.register(require('./lang/jolie.js'))
refractor.register(require('./lang/jq.js'))
refractor.register(require('./lang/js-extras.js'))
refractor.register(require('./lang/js-templates.js'))
refractor.register(require('./lang/jsdoc.js'))
refractor.register(require('./lang/json.js'))
refractor.register(require('./lang/json5.js'))
refractor.register(require('./lang/jsonp.js'))
refractor.register(require('./lang/jsstacktrace.js'))
refractor.register(require('./lang/jsx.js'))
refractor.register(require('./lang/julia.js'))
refractor.register(require('./lang/keyman.js'))
refractor.register(require('./lang/kotlin.js'))
refractor.register(require('./lang/kumir.js'))
refractor.register(require('./lang/kusto.js'))
refractor.register(require('./lang/latex.js'))
refractor.register(require('./lang/latte.js'))
refractor.register(require('./lang/less.js'))
refractor.register(require('./lang/lilypond.js'))
refractor.register(require('./lang/liquid.js'))
refractor.register(require('./lang/lisp.js'))
refractor.register(require('./lang/livescript.js'))
refractor.register(require('./lang/llvm.js'))
refractor.register(require('./lang/log.js'))
refractor.register(require('./lang/lolcode.js'))
refractor.register(require('./lang/lua.js'))
refractor.register(require('./lang/magma.js'))
refractor.register(require('./lang/makefile.js'))
refractor.register(require('./lang/markdown.js'))
refractor.register(require('./lang/markup-templating.js'))
refractor.register(require('./lang/matlab.js'))
refractor.register(require('./lang/maxscript.js'))
refractor.register(require('./lang/mel.js'))
refractor.register(require('./lang/mermaid.js'))
refractor.register(require('./lang/mizar.js'))
refractor.register(require('./lang/mongodb.js'))
refractor.register(require('./lang/monkey.js'))
refractor.register(require('./lang/moonscript.js'))
refractor.register(require('./lang/n1ql.js'))
refractor.register(require('./lang/n4js.js'))
refractor.register(require('./lang/nand2tetris-hdl.js'))
refractor.register(require('./lang/naniscript.js'))
refractor.register(require('./lang/nasm.js'))
refractor.register(require('./lang/neon.js'))
refractor.register(require('./lang/nevod.js'))
refractor.register(require('./lang/nginx.js'))
refractor.register(require('./lang/nim.js'))
refractor.register(require('./lang/nix.js'))
refractor.register(require('./lang/nsis.js'))
refractor.register(require('./lang/objectivec.js'))
refractor.register(require('./lang/ocaml.js'))
refractor.register(require('./lang/opencl.js'))
refractor.register(require('./lang/openqasm.js'))
refractor.register(require('./lang/oz.js'))
refractor.register(require('./lang/parigp.js'))
refractor.register(require('./lang/parser.js'))
refractor.register(require('./lang/pascal.js'))
refractor.register(require('./lang/pascaligo.js'))
refractor.register(require('./lang/pcaxis.js'))
refractor.register(require('./lang/peoplecode.js'))
refractor.register(require('./lang/perl.js'))
refractor.register(require('./lang/php-extras.js'))
refractor.register(require('./lang/php.js'))
refractor.register(require('./lang/phpdoc.js'))
refractor.register(require('./lang/plsql.js'))
refractor.register(require('./lang/powerquery.js'))
refractor.register(require('./lang/powershell.js'))
refractor.register(require('./lang/processing.js'))
refractor.register(require('./lang/prolog.js'))
refractor.register(require('./lang/promql.js'))
refractor.register(require('./lang/properties.js'))
refractor.register(require('./lang/protobuf.js'))
refractor.register(require('./lang/psl.js'))
refractor.register(require('./lang/pug.js'))
refractor.register(require('./lang/puppet.js'))
refractor.register(require('./lang/pure.js'))
refractor.register(require('./lang/purebasic.js'))
refractor.register(require('./lang/purescript.js'))
refractor.register(require('./lang/python.js'))
refractor.register(require('./lang/q.js'))
refractor.register(require('./lang/qml.js'))
refractor.register(require('./lang/qore.js'))
refractor.register(require('./lang/qsharp.js'))
refractor.register(require('./lang/r.js'))
refractor.register(require('./lang/racket.js'))
refractor.register(require('./lang/reason.js'))
refractor.register(require('./lang/regex.js'))
refractor.register(require('./lang/rego.js'))
refractor.register(require('./lang/renpy.js'))
refractor.register(require('./lang/rest.js'))
refractor.register(require('./lang/rip.js'))
refractor.register(require('./lang/roboconf.js'))
refractor.register(require('./lang/robotframework.js'))
refractor.register(require('./lang/ruby.js'))
refractor.register(require('./lang/rust.js'))
refractor.register(require('./lang/sas.js'))
refractor.register(require('./lang/sass.js'))
refractor.register(require('./lang/scala.js'))
refractor.register(require('./lang/scheme.js'))
refractor.register(require('./lang/scss.js'))
refractor.register(require('./lang/shell-session.js'))
refractor.register(require('./lang/smali.js'))
refractor.register(require('./lang/smalltalk.js'))
refractor.register(require('./lang/smarty.js'))
refractor.register(require('./lang/sml.js'))
refractor.register(require('./lang/solidity.js'))
refractor.register(require('./lang/solution-file.js'))
refractor.register(require('./lang/soy.js'))
refractor.register(require('./lang/sparql.js'))
refractor.register(require('./lang/splunk-spl.js'))
refractor.register(require('./lang/sqf.js'))
refractor.register(require('./lang/sql.js'))
refractor.register(require('./lang/squirrel.js'))
refractor.register(require('./lang/stan.js'))
refractor.register(require('./lang/stylus.js'))
refractor.register(require('./lang/swift.js'))
refractor.register(require('./lang/systemd.js'))
refractor.register(require('./lang/t4-cs.js'))
refractor.register(require('./lang/t4-templating.js'))
refractor.register(require('./lang/t4-vb.js'))
refractor.register(require('./lang/tap.js'))
refractor.register(require('./lang/tcl.js'))
refractor.register(require('./lang/textile.js'))
refractor.register(require('./lang/toml.js'))
refractor.register(require('./lang/tsx.js'))
refractor.register(require('./lang/tt2.js'))
refractor.register(require('./lang/turtle.js'))
refractor.register(require('./lang/twig.js'))
refractor.register(require('./lang/typescript.js'))
refractor.register(require('./lang/typoscript.js'))
refractor.register(require('./lang/unrealscript.js'))
refractor.register(require('./lang/uri.js'))
refractor.register(require('./lang/v.js'))
refractor.register(require('./lang/vala.js'))
refractor.register(require('./lang/vbnet.js'))
refractor.register(require('./lang/velocity.js'))
refractor.register(require('./lang/verilog.js'))
refractor.register(require('./lang/vhdl.js'))
refractor.register(require('./lang/vim.js'))
refractor.register(require('./lang/visual-basic.js'))
refractor.register(require('./lang/warpscript.js'))
refractor.register(require('./lang/wasm.js'))
refractor.register(require('./lang/wiki.js'))
refractor.register(require('./lang/wolfram.js'))
refractor.register(require('./lang/wren.js'))
refractor.register(require('./lang/xeora.js'))
refractor.register(require('./lang/xml-doc.js'))
refractor.register(require('./lang/xojo.js'))
refractor.register(require('./lang/xquery.js'))
refractor.register(require('./lang/yaml.js'))
refractor.register(require('./lang/yang.js'))
refractor.register(require('./lang/zig.js'))
