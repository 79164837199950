'use strict'

module.exports = haxe
haxe.displayName = 'haxe'
haxe.aliases = []
function haxe(Prism) {
  Prism.languages.haxe = Prism.languages.extend('clike', {
    // Strings can be multi-line
    string: {
      pattern: /(["'])(?:(?!\1)[^\\]|\\[\s\S])*\1/,
      greedy: true,
      inside: {
        interpolation: {
          pattern: /(^|[^\\])\$(?:\w+|\{[^}]+\})/,
          lookbehind: true,
          inside: {
            interpolation: {
              pattern: /^\$\w*/,
              alias: 'variable'
            } // See rest below
          }
        }
      }
    },
    // The final look-ahead prevents highlighting of keywords if expressions such as "haxe.macro.Expr"
    keyword:
      /\bthis\b|\b(?:abstract|as|break|case|cast|catch|class|continue|default|do|dynamic|else|enum|extends|extern|from|for|function|if|implements|import|in|inline|interface|macro|new|null|override|public|private|return|static|super|switch|throw|to|try|typedef|using|var|while)(?!\.)\b/,
    operator: /\.{3}|\+\+?|-[->]?|[=!]=?|&&?|\|\|?|<[<=]?|>[>=]?|[*\/%~^]/
  })
  Prism.languages.insertBefore('haxe', 'class-name', {
    regex: {
      pattern: /~\/(?:[^\/\\\r\n]|\\.)+\/[igmsu]*/,
      greedy: true
    }
  })
  Prism.languages.insertBefore('haxe', 'keyword', {
    preprocessor: {
      pattern: /#\w+/,
      alias: 'builtin'
    },
    metadata: {
      pattern: /@:?\w+/,
      alias: 'symbol'
    },
    reification: {
      pattern: /\$(?:\w+|(?=\{))/,
      alias: 'variable'
    }
  })
  Prism.languages.haxe['string'].inside['interpolation'].inside.rest =
    Prism.languages.haxe
  delete Prism.languages.haxe['class-name']
}
